// PersonaValueProvider.js

import React, { createContext, useContext, useState } from 'react';

const PersonaContext = createContext();

export function PersonaValueProvider({ children }) {
    const [prosonaValue, setProsonaValue] = useState('');

    const contextValue = {
        prosonaValue,
        setProsonaValue,
    };

    return (
        <PersonaContext.Provider value={contextValue}>
            {children}
        </PersonaContext.Provider>
    );
}

export function usePersonaValue() {
    const context = useContext(PersonaContext);
    if (!context) {
        throw new Error('usePersonaValue must be used within a PersonaValueProvider');
    }
    return context;
}
