import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import chatsSlice from "./slices/chatsSlice";
import adminSlice from "./slices/adminSlice";
import personaSlice from "./slices/personaSlice";

const store = configureStore({
    reducer: {
        user: userSlice,
        userChats: chatsSlice,
        admin: adminSlice,
        persona: personaSlice
    }
})

export default store