import React, { createContext, useContext, useState, useEffect } from 'react';

const handelId = createContext();

export function ChatProvider({ children }) {
    const [chatId, setChatId] = useState(null);
    const [chats, setChats] = useState([]);
    const [chatConversations, setChatConversations] = useState([]);
    const [getModel, setGetModel] = useState('');
    const [getTitle, setGetTitle] = useState('');
    const [prosonaValue, setProsonaValue] = useState('');
    const [firstCharacters, setFirstCharacters] = useState('');
    const [token, setToken] = useState();

    const contextValue = {
        chatId,
        setChatId,
        chats,
        setChats,
        chatConversations,
        setChatConversations,
        getModel,
        setGetModel,
        getTitle,
        setGetTitle,
        prosonaValue,
        setProsonaValue,
        firstCharacters,
        setFirstCharacters,
        token,
        setToken
    };

    return (
        <handelId.Provider value={contextValue}>
            {children}
        </handelId.Provider>
    );
}

export function useChat() {
    return useContext(handelId);
}
