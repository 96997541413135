import React from 'react'
import { useEffect, useState } from 'react'
import api from '../services/ApiUrl';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useChat } from '../handleId';

function ResetValidation() {
    const navigate = useNavigate();
    // const [token, setToken] = useState('');
    const { token, setToken } = useChat();

    useEffect(() => {
        const currentUrl = window.location.href;

        const urlParts = currentUrl.split('?');
        console.log(urlParts)
        if (urlParts.length === 2) {
            const token = urlParts[1];
            console.log(token, 'token')
            setToken(token);
            localStorage.setItem('reset-token', token)
        }

        const payload = {
            token: token
        };
        console.log(payload)
        axios.get('https://zetapi.techenablers.info/api/reset-password/?token=', { payload })
            .then((response) => {
                console.log('API Response:', response);
                if (response.data.success === true) {
                    toast.success(response.data.messages[0]);
                    navigate(`/resetpassword`);
                } else if (response.data.success === false) {
                    toast.error(response.data.messages[0]);
                    navigate('/forgetpassword');
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
                if (error.code === "ERR_NETWORK") {
                    toast.error('NetWork Error');
                }
            });
    }, []);

    const handleToken = (e) => {
        e.preventDefault();

    }


    return (
        <div className='login-page overflow-y-auto'>
            <div className='login-box'>
                <div className='login-logo'>
                    <h4 className='heading'>Checking the token validation</h4>
                    <p>Please wait...</p>
                </div>
            </div>
        </div>
    )
}

export default ResetValidation
