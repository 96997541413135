import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import NewAccount from "./Pages/Auth/NewAccount";
import TellUs from "./Pages/Auth/TellUs";
import Number from "./Pages/Auth/Number";
import ContentCopy from './Pages/ContentCopy.jsx'
import ChangePassword from "./Pages/Auth/ChangePassword";
import Form from './Pages/Form.jsx'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Admin } from "./Pages/admin/Admin";
import EditUser from "./Pages/admin/EditUser";
import Auth from './Pages/Protected/Auth';
import ForgetPassword from "./Pages/Auth/ForgetPassword";
import ValidationPage from "./Pages/Auth/ValidationPage";
import Conversations from "./Pages/Conversations";
import ResetPassword from "./Pages/Auth/ResetPassword";
import ResetValidation from "./Pages/Auth/ResetValidation";
import { PersonaProvider } from "./Pages/personaContext";
import { ChatProvider } from "./Pages/handleId";
import { PersonaValueProvider } from "./Pages/prosonaValue";

function App() {
  return (
    <div>
      <ToastContainer />
      <PersonaProvider>
        <ChatProvider>
          <PersonaValueProvider>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/admin/*" element={<Auth Component={Admin} />} />
              <Route path="/edituser/:id" element={<Auth Component={EditUser} />} />
              <Route path='/newaccount' element={<NewAccount />} />
              <Route path='/changepassword' element={<Auth Component={ChangePassword} />} />
              <Route path='/telluseaboutyou' element={<TellUs />} />
              <Route path='/number' element={<Number />} />
              <Route path='/content' element={<Auth Component={ContentCopy} />} />
              <Route path='/c/:id' element={<Auth Component={Conversations} />} />
              <Route path='/forgetpassword' element={<ForgetPassword />} />
              <Route path='/resetpassword' element={<ResetPassword />} />
              <Route path='/form' element={<Form />} />
              <Route path="/verify/code" element={<ValidationPage />} />
              <Route path="/reset-password/token" element={<ResetValidation />} />
              <Route path='*' element={<Navigate to="/" />} />
            </Routes>
          </PersonaValueProvider>
        </ChatProvider>
      </PersonaProvider>
    </div>
  );
}

export default App;
