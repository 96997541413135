import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
function Theme() {
    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false);
    };
    return (
        <div>
            {/* <Modal className='editorder_window' show={show} onHide={handleClose}>
                <Modal.Header className='header-close'>
                    <button className='close-icon' onClick={handleClose}></button>
                </Modal.Header>
                <Modal.Body>

                    <div className="modal-div">
                        <div className="row">
                            <div className="col-md-12 tem-style">
                                <label>Model</label>
                                <select class="custom-select selector-modal">
                                    <option selected>Select Modle</option>
                                    <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
                                    <option value="gpt-3.5-turbo-16k">gpt-3.5-turbo-16k</option>
                                    <option value="gpt-4">gpt-4</option>
                                </select>
                            </div>

                            <div className="col-md-12">
                                <form>
                                    <div className="row">

                                        <div className='col-md-6 textarea-div'>
                                            <label>Persona</label>
                                            <textarea placeholder='Write...' ></textarea>
                                        </div>

                                        <div className='col-md-6 textarea-div'>
                                            <label>Question</label>
                                            <textarea placeholder='Write...'></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className='modal-footer-div'>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 modal-btn-div">
                            <button className='btn btn-block btn-modal' type='button'>
                                'Submit'
                            </button>
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                </Modal.Footer>
            </Modal> */}
            <h1>hello</h1>
        </div>
    )
}

export default Theme
