import React, { useState, useEffect } from 'react'
import AdminSidebar from './AdminSidebar'
import api from "../services/ApiUrl";
import { Link, useParams, useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { getEditUser, postEditUser } from '../../redux/slices/adminSlice';
import { useDispatch, useSelector } from 'react-redux';

function EditUser({ isSidebarVisible }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.admin.adminData.users || []);
    console.log(user, 'testing editUser')

    const [contentClass, setContentClass] = useState('');

    const toggleSidebarClass = (newClass) => {
        setContentClass(newClass);
    };

    const param = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [status, setStatus] = useState(false)
    // const [user, setUser] = useState([]);
    const [editedUser, setEditedUser] = useState({
        email: '',
        roles: '',
        credit_limit: '',
        is_active: status,
        password: ''
    })

    const [showError, setShowError] = useState(false)
    const [error, setError] = useState()

    useEffect(() => {
        setIsLoading(true)
        // api.get(`/user/${param.id}`)
        //     .then((res) => {
        //         console.log(res.data[0].users, 'edit user');
        //         setUser(res.data[0].users)
        //         setEditedUser(res.data[0].users)
        //         setStatus(res.data[0].users.is_active);
        //     }).catch((error) => {
        //         console.log(error)
        //     }).finally(() => {
        //         setIsLoading(false)
        //     })
        const id = param.id;
        dispatch(getEditUser({ id }))
            .then((res) => {
                console.log(res, 'res editUSer api')
                if (res.payload.success === true) {
                    setStatus(res.payload.users.is_active);
                    setEditedUser(res.payload.users);
                } else if (res.payload.code === "ERR_NETWORK") {
                    setShowError(true);
                    setError(res.payload.message);
                } else {
                    console.log(res);
                    setShowError(true);
                    setError(res.payload.data.detail)
                    // console.log(res.payload.data).d
                }

            }).finally(() => {
                setIsLoading(false)
            })
    }, [])

    const submitEdit = (e) => {
        setIsEditing(true)
        e.preventDefault();
        let payload = {
            email: editedUser.email,
            roles: editedUser.roles,
            credit_limit: editedUser.credit_limit,
            is_active: status,
            password: editedUser.password
        }
        const id = param.id;
        // api.post(`/user/${param.id}`, payload)
        //     .then((res) => {
        //         console.log(res, 'post edit');
        //         if (res.data[0].success === true) {
        //             navigate('/admin')
        //             toast.success(res.data[0].messages[0])
        //         }

        //     }).catch((error) => {
        //         console.log(error, 'post error')
        //     }).finally(() => {
        //         setIsEditing(false)
        //     })

        dispatch(postEditUser({ id, payload }))
            .then((res) => {
                console.log(res, 'edit user post api res')
                if (res.payload.success === true) {
                    navigate('/admin');
                    toast.success(res.payload.messages[0]);
                } else if (res.payload.status !== 200) {
                    toast.error(res.payload.data.detail)
                    // setError(res.payload.data.detail)
                    // console.log()
                    // setShowError(true)
                }
            }).finally(() => {
                setIsEditing(false)
            })
    }

    const handleStatus = (e) => {
        const checked = e.target.checked;
        setStatus(checked)
        console.log(checked)
        let payload = {
            is_active: checked
        }
        api.post(`/user/status/${param.id}`, payload)
            .then((res) => {
                console.log(res, 'status')
                setStatus(res.data[0].users.is_active)
            }).catch((error) => {
                console.log(error)
            })
    }
    return (
        <div className='wrapper'>
            <AdminSidebar isSidebarVisible={isSidebarVisible} toggleSidebarClass={toggleSidebarClass} />
            <div className='main-wrapper'>
                <div className='dashboard'>
                    <div className='content'>
                        <main className='h-full w-full flex flex-col flex-1'>
                            <div className="wrapper-container">
                                <div className='dashboard-main-content_user'>
                                    {isLoading ? (
                                        <>
                                            <ClipLoader loading={isLoading} size={20} color="#050505" />

                                        </>
                                    ) : (
                                        <>

                                            <Link to='/admin'><button className='btn btn-primary' style={{ cursor: 'pointer' }}>Back To Admin</button></Link>
                                            <form className='user-form' onSubmit={submitEdit}>
                                                {showError ?
                                                    <div>{error}</div>
                                                    : (
                                                        <>
                                                            <div className="form-group">
                                                                <label for="inputAddress2">Email</label>
                                                                <input type="email" className="form-control" id="inputAddress2" placeholder="Email" defaultValue={user.email} onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="inputAddress2">Password</label>
                                                                <input type="password" className="form-control" id="inputAddress2" placeholder="Password" defaultValue={user.password} onChange={(e) => setEditedUser({ ...editedUser, password: e.target.value })} />
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <label for="inputEmail4">Role</label>
                                                                    <select className="form-control" defaultValue={user.roles} onChange={(e) => setEditedUser({ ...editedUser, roles: e.target.value })}>
                                                                        <option value="User">User</option>
                                                                        <option value="Admin">Admin</option>
                                                                    </select>
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label for="inputPassword4">Credit Limit</label>
                                                                    <input type="text" className="form-control" id="inputPassword4" placeholder="Credit Limit" defaultValue={user.credit_limit} onChange={(e) => setEditedUser({ ...editedUser, credit_limit: e.target.value })} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="gridCheck" checked={status} onChange={(e) => handleStatus(e)} />
                                                                    <label className="form-check-label" for="gridCheck">
                                                                        Active
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <button type="submit" className="btn btn-primary">
                                                                {isEditing ? (
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                                                        <ClipLoader loading={isEditing} size={20} color="#050505" />

                                                                    </div>
                                                                ) : (
                                                                    'Edit'
                                                                )}

                                                            </button>
                                                        </>
                                                    )}
                                            </form>

                                        </>
                                    )
                                    }


                                </div>
                            </div>

                        </main>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default EditUser
