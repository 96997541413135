import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../services/ApiUrl';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';

function NewAccount() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState();
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [error, setError] = useState([]);
    const [closeError, setCloseError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const clearErrors = () => {
        setError([]);
    };

    const handelSignUp = (e) => {
        e.preventDefault();
        if (!password) {
            setError(["Enter Password"]);
            return;
        }
        setIsLoading(true)
        let formData = {
            email: email,
            password: password,
            first_name: first_name,
            last_name: last_name
        };
        api.post('/signup', formData)
            .then((resp) => {
                console.log(resp, 'sign up');
                if (resp.data.success === true) {
                    setEmail('');
                    setPassword('');
                    setFirstName('');
                    setLastName('');
                    toast.success('check your email');
                    navigate('/');
                } else if (resp.data.success === false) {
                    setError(resp.data.messages)
                    console.log('hello nauman')
                } else if (resp.data[0].success === false) {
                    setError(resp.data[0].messages)
                    console.log('hello nauman2')
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    setError(['NetWork Error']);
                }
                // setError([error.response.data.detail]);
                setTimeout(() => {
                    setError([]);
                }, 5000)
            }).finally(() => {
                setIsLoading(false)
                setTimeout(() => {
                    setError([]);
                }, 5000)
            })
    };

    const customLoaderStyles = {
        width: '20px',
        height: '20px',
        marginTop: '9px',
        borderRadius: '100%',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'rgb(5, 5, 5) rgb(5, 5, 5) transparent',
        borderImage: 'initial',
        display: 'inline-block',
        animation: '0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip',
    };

    return (
        <div className='login-page overflow-y-auto'>
            <div className='login-box'>
                <div className='login-logo'>
                    <h4 className='heading'>Create your account</h4>
                    <p className='para'>Note that phone verification may be required for signup. Your number will only be used to verify your identity for security purposes.</p>
                </div>
                <div className='card'>
                    {error.length > 0 &&
                        error.map((e, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className={` ${closeError ? 'hiddenError' : 'error'}`}>
                                        <div className='error-div'>
                                            <div className='msg'>
                                                <p>{e}</p>
                                            </div>
                                            <div className='close-icon' onClick={clearErrors}></div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    <form action="" onSubmit={handelSignUp}>
                        <div className='card-body'>
                            <input type='text' placeholder='First Name' className='email-input' value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                            <input type='text' placeholder='Last Name' className='email-input' value={last_name} onChange={(e) => setLastName(e.target.value)} />
                            <input type='email' placeholder='Email address' className='email-input' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <input type='password' placeholder='Password' className='email-input' value={password} onChange={(e) => setPassword(e.target.value)} />
                            <button className='btn btn-continue' onClick={handelSignUp}>
                                {isLoading ? (
                                    <ClipLoader style={customLoaderStyles} loading={isLoading} size={20} color="#050505" />
                                ) :
                                    'Continue'
                                }
                            </button>
                            <div className='sign-para'>
                                <p>
                                    Already have an account? <span><Link style={{ color: '#10A37F' }} to='/'>Log in</Link></span>
                                </p>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
}

export default NewAccount;
