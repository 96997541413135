import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import api from "../../Pages/services/ApiUrl";

export const fetchConversations = createAsyncThunk('fetchConversations', async (chatId) => {
    try {
        const response = await api.get(`/conversations/${chatId}`);
        return response.data[0];
    } catch (error) {
        if (error.code === "ERR_NETWORK") {
            return error
        } else {
            return error.response
        }
    }
})

export const postConversation = createAsyncThunk('postConversation', async ({ chatId, payload }) => {
    try {
        const response = await api.post(`/conversations/prompt/${chatId}`, payload);
        // console.log(response, 'post conversation response')
        return response.data;
    } catch (error) {
        console.log(error, 'post conversation error')
        return error;
    }
});

export const deleteConversation = createAsyncThunk('deleteConversation', async ({ id }) => {
    try {
        console.log(id, 'id2')
        const response = api.delete(`/conversation/{id}?conversation_id=${id}`);
        console.log(response, 'deleteConversation response');
        return response
    } catch (error) {
        console.log(error, 'deleteConversation error')
    }
})

export const postTemplate = createAsyncThunk('postTemplate', async ({ chatId, payload }) => {
    try {
        const response = await api.post(`/conversations/prompt/${chatId}`, payload);
        // console.log(response, 'post template response');
        return response.data;
    } catch (error) {
        // console.log(error, 'post template error response');
        if (error.code === "ERR_NETWORK") {
            // console.log('network error in post template')
            return error
        } else {
            // console.log('other error in post template')
            return error.response
        }
    }
})

export const addEmptyConversation = createAction('addEmptyConversation');


const userSlice = createSlice({
    name: 'user',
    initialState: {
        conversations: [],
        error: null,
        isLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {

        // builder.addCase(fetchConversations.pending, (state, action) => {
        //     state.error = null;
        //     state.isLoading = true
        // });

        builder.addCase(fetchConversations.fulfilled, (state, action) => {
            // console.log(action, 'action')
            state.conversations = action.payload;
            state.error = null;
            state.isLoading = false
        });

        builder.addCase(postConversation.fulfilled, (state, action) => {
            state.error = null;
            // state.conversations = action.payload;
        });

        builder.addCase(postConversation.rejected, (state, action) => {
            // console.log(action, 'post conversation error')
            state.error = action.payload;
            state.conversations = null;
        });

        builder.addCase(deleteConversation.fulfilled, (state, action) => {
            console.log(action, 'action deleteConversation');
            // state.conversations = action.payload
        })


        builder.addCase(postTemplate.fulfilled, (state, action) => {
            // console.log(action, 'post template fulfilled action')
            const responseData = {
                data: action.payload.data,
                status: action.payload.status,
            };

            state.error = null;
            state.conversations = responseData;
        });

        builder.addCase(postTemplate.rejected, (state, action) => {
            // console.log(action, 'post template rejected action')
            state.error = action.payload;
            state.conversations = null
            // state.conversations = action.payload;
        });

        builder.addCase(addEmptyConversation, (state, action) => {
            console.log(action, 'push')
            state.conversations = []
        });

    }
})


export default userSlice.reducer