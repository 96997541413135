// personaSlice.js

import { createSlice } from '@reduxjs/toolkit';

const personaSlice = createSlice({
    name: 'persona',
    initialState: {
        persona: '',
        prosonaValue: '', // Add the prosonaValue state
    },
    reducers: {
        setPersona: (state, action) => {
            state.persona = action.payload;
        },
        setProsonaValue: (state, action) => {
            state.prosonaValue = action.payload;
        },
    },
});

export const { setPersona, setProsonaValue } = personaSlice.actions;

export default personaSlice.reducer;
