import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Pages/services/ApiUrl";

export const adminUsers = createAsyncThunk('adminUsers', async () => {
    const response = await api.get('/users');
    console.log(response.data[0], 'admin users response');
    return response.data[0];
})

export const userStatus = createAsyncThunk('userStatus', async ({ id, payload }) => {
    try {
        const response = await api.post(`/user/status/${id}`, payload);
        console.log(response, 'change status response');
        return response.data[0];
    } catch (error) {
        console.log(error, 'change status error');
        return error;
    }
})

export const getEditUser = createAsyncThunk('getEditUser', async ({ id }) => {
    try {
        const response = await api.get(`/user/${id}`);
        console.log(response, 'getEditUser response');
        return response.data[0];
    } catch (error) {
        console.log(error, 'getEditUser error');
        if (error.code === "ERR_NETWORK") {
            return error
        } else {
            return error.response;
        }
    }
})

export const postEditUser = createAsyncThunk('postEditUser', async ({ id, payload }) => {
    try {
        const response = await api.post(`/user/${id}`, payload);
        console.log(response, 'post edituser')
        return response.data[0];
    } catch (error) {
        console.log(error)
        return error.response
    }
})

export const deleteUser = createAsyncThunk('deleteUser', async ({ id }) => {
    try {
        const response = await api.delete(`/user/${id}`);
        console.log(response, 'delete user')
        return response.data[0];
    } catch (error) {
        console.log(error)
        return error
    }
})

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        adminData: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(adminUsers.fulfilled, (state, action) => {
            console.log(action, 'admin users action');
            console.log(state, 'state');
            state.error = null;
            state.adminData = action.payload;
            state.isLoading = false;
        })

        // builder.addCase(userStatus.fulfilled, (state, action) => {
        //     console.log(action, 'status action');
        //     state.error = null;
        //     state.adminData = action.payload
        // })

        builder.addCase(userStatus.fulfilled, (state, action) => {
            const { id, is_active } = action.payload;
            const userToUpdate = state.adminData.users.find((user) => user.id === id);
            if (userToUpdate) {
                userToUpdate.is_active = is_active;
            }
        });

        builder.addCase(getEditUser.fulfilled, (state, action) => {
            console.log(action, 'getEditUser action');
            state.adminData = action.payload;
        });

        builder.addCase(postEditUser.fulfilled, (state, action) => {
            console.log(action.payload, 'post edituser action');
            if (action.payload.success === true) {
                state.adminData = action.payload.users
            } else {
                state.adminData = action.payload
            }
        })

        builder.addCase(deleteUser.fulfilled, (state, action) => {
            console.log(action, 'delete edituser action');
            state.adminData = action.payload
        })
    }

})

export default adminSlice.reducer