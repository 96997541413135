import React, { useState, useEffect } from "react";
import AdminSidebar from "./AdminSidebar";
import '../../AdminSass/admin.scss'
import api from "../services/ApiUrl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { adminUsers, deleteUser, userStatus } from "../../redux/slices/adminSlice";
import Modal from 'react-bootstrap/Modal';

export function Admin({ isSidebarVisible }) {

    const toggleSidebarClass = (newClass) => {
        setContentClass(newClass);
    };

    const [contentClass, setContentClass] = useState('');
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState(false)
    const [showError, setShowError] = useState(false)
    const [error, setError] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [openSetting, setOpenSetting] = useState(false);

    const [themeValue, setThemeValue] = useState();
    const theme = localStorage.getItem('theme')

    const dispatch = useDispatch();
    // const users = useSelector((state) => state.admin.adminData.users || []);
    // console.log(users, 'getting user of admin')

    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        setIsLoading(true)
        api.get('/users')
            .then((res) => {
                setUsers(res.data[0].users)
            }).catch((error) => {
                console.log(error)
                if (error.code === "ERR_NETWORK") {
                    setShowError(true);
                    setError(error.messages)
                }
            }).finally(() => {
                setIsLoading(false)
            })

        // dispatch(adminUsers())
        //     .then((res) => {
        //         console.log(res, 'res of admin users in admin crud')
        //     }).catch((error) => {
        //         console.log(error, 'users error')
        //     }).finally(() => {
        //         setIsLoading(false)
        //     })
    }

    const handleEdit = (id) => {
        navigate('/edituser/' + id)
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // api.delete(`/user/${id}`)
                //     .then((res) => {
                //         console.log(res, 'delete res')
                //         toast.success(res.data[0].messages[0])
                //         getUsers();
                //     }).catch((error) => {
                //         console.log(error)
                //     })

                dispatch(deleteUser({ id }))
                    .then((res) => {
                        console.log(res, 'delete res api')
                        if (res.payload.success === true) {
                            toast.success(res.payload.messages[0]);
                            getUsers();
                        } else if (res.payload.code === "ERR_NETWORK") {
                            toast.error("Network Error")
                        } else if (res.payload.status !== 200) {
                            toast.error(res.payload.response.data.detail)
                        }
                    })
            }
        })

    }

    // const handleStatus = (e, id) => {
    //     Swal.fire({
    //         title: 'Do you want to change Status?',
    //         showDenyButton: true,
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes',
    //         denyButtonText: `No`,
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             const checked = e.target.checked;
    //             let payload = {
    //                 is_active: checked
    //             }
    //             api.post(`/user/status/${id}`, payload)
    //                 .then((res) => {
    //                     console.log(res, 'status')
    //                     toast.success(res.data[0].messages[0])
    //                 }).catch((error) => {
    //                     console.log(error)
    //                 })
    //         }
    //     })

    // }

    const [statusUser, setStatesUser] = useState(users)

    const handleStatus = (e, id) => {

        const checked = e.target.checked;
        Swal.fire({
            title: 'Do you want to change Status?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                let payload = {
                    is_active: checked,
                };

                api.post(`/user/status/${id}`, payload)
                    .then((res) => {
                        console.log(res, 'status');
                        toast.success(res.data[0].messages[0]);
                        setUsers((prevUsers) =>
                            prevUsers.map((user) =>
                                user.id === id ? { ...user, is_active: checked } : user
                            )
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                // dispatch(userStatus({ id, payload }))
                //     .then((res) => {
                //         console.log(res, 'user status in admin');
                //         toast.success(res.payload.messages[0]);
                //         const updatedStatus = res.payload.users.is_active;
                //         console.log(updatedStatus)
                //     })
            }
        });
    };

    const handleSetting = () => {
        setOpenSetting(!openSetting)
    }

    const handleThemeChange = (e) => {
        const newValue = e.target.value;
        setThemeValue(newValue);
        localStorage.setItem('theme', newValue);

        handleSetting()
    };


    return (
        <div>
            <div className='wrapper'>
                <AdminSidebar isSidebarVisible={isSidebarVisible} toggleSidebarClass={toggleSidebarClass} handleSetting={handleSetting} />
                <div className={`main-wrapper ${theme === "Dark" ? 'main-wrapper_dark' : ''}`}>
                    <div className='dashboard'>
                        <div className='content'>
                            <main className='h-full w-full flex flex-col flex-1'>
                                <div className="wrapper-container">
                                    <div className='dashboard-main-content_admin'>
                                        {isLoading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                <ClipLoader loading={isLoading} size={20} color="#050505" />
                                            </div>
                                        ) :

                                            (
                                                <>
                                                    {showError ? (
                                                        <div>
                                                            {error}
                                                        </div>
                                                    ) : (
                                                        <table className="table table-md table-hover users-table">
                                                            <thead className="thead">
                                                                <tr>
                                                                    <th className={`${theme === 'Dark' ? 'p-white' : ''}`}>#</th>
                                                                    <th className={`${theme === 'Dark' ? 'p-white' : ''}`} scope="col">Email</th>
                                                                    <th className={`${theme === 'Dark' ? 'p-white' : ''}`} scope="col">Role</th>
                                                                    <th className={`${theme === 'Dark' ? 'p-white' : ''}`} scope="col">Credit Limit</th>
                                                                    <th className={`${theme === 'Dark' ? 'p-white' : ''}`} scope="col">Status</th>
                                                                    <th className={`${theme === 'Dark' ? 'p-white' : ''}`} scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {users.map((user, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td className={`${theme === 'Dark' ? 'back-dark' : ''}`}>{i + 1}</td>
                                                                                <td className={`${theme === 'Dark' ? 'back-dark' : ''}`}>{user.email}</td>
                                                                                <td className={`${theme === 'Dark' ? 'back-dark' : ''}`}>{user.roles}</td>
                                                                                <td className={`${theme === 'Dark' ? 'back-dark' : ''}`}>
                                                                                    {user.credit_limit}
                                                                                </td>
                                                                                <td className={`${theme === 'Dark' ? 'back-dark' : ''}`}>
                                                                                    <input type="checkbox" checked={user.is_active} onChange={(e) => handleStatus(e, user.id)} />
                                                                                </td>
                                                                                <td className={`${theme === 'Dark' ? 'back-dark' : ''}`}>
                                                                                    <button style={{ background: '#fff', border: 'none', fontSize: '12px', cursor: 'pointer', color: '#3d84dd' }} onClick={() => handleEdit(user.id)}><i className="fas fa-edit"></i></button> <button style={{ background: '#fff', border: 'none', fontSize: '12px', cursor: 'pointer', color: '#3d84dd' }} onClick={() => handleDelete(user.id)}><i className="fas fa-trash"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    )}

                                                </>
                                            )}

                                        {/* <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card">

                                                        <div className="card-body">
                                                            <button className='btn btn-success'>Add Coupon</button>
                                                            <br /><br />
                                                            <div className="input-group">
                                                                <input type="text" className="form-control form-control-lg" placeholder="Type your keywords here" />
                                                                <div className="input-group-append">
                                                                    <button type="submit" className="btn btn-lg btn-success" >
                                                                        <i className="fa fa-search"></i>
                                                                    </button>
                                                                    <button type="submit" className="btn btn-lg btn-danger" >
                                                                        <i className="fa fa-times"></i>
                                                                    </button>
                                                                </div>
                                                            </div><br />


                                                            <table className="table" style={{ marginBottom: '30px' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr.#</th>
                                                                        <th>Code</th>
                                                                        <th>Discount</th>
                                                                        <th>Discount Type</th>
                                                                        <th>Expire Date</th>
                                                                        <th>General</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>1</b></td>
                                                                        <td>code</td>
                                                                        <td>discount%</td>
                                                                        <td>
                                                                            <span className="badge badge-warning">
                                                                                discount_type
                                                                            </span>
                                                                        </td>
                                                                        <td></td>
                                                                        <td>
                                                                            <span className="badge badge-success">
                                                                                Yes
                                                                            </span>

                                                                        </td>

                                                                        <td>
                                                                            <i class="fas fa-edit" style={{ fontSize: '12px', cursor: 'pointer', color: '#3d84dd' }}></i> <i class="fas fa-trash" style={{ fontSize: '12px', cursor: 'pointer', color: '#3d84dd' }}></i> <i class="fas fa-eye" style={{ fontSize: '12px', cursor: 'pointer', color: '#3d84dd' }}></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                            </main>
                        </div>
                    </div>
                </div>


            </div>

            <Modal className='editorder_window' show={openSetting} onHide={handleSetting}>
                <Modal.Header className='header-close'>
                    <button className='close-icon' onClick={handleSetting}></button>
                </Modal.Header>
                <Modal.Body>

                    <div className="modal-div">
                        <div className="row">
                            <div className="col-md-12 tem-style">
                                <label>Theme</label>
                                <select class="custom-select selector-modal" onChange={handleThemeChange}>
                                    <option selected disabled>Select Theme</option>
                                    <option value='Light'>Light</option>
                                    <option value="Dark">Dark</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}