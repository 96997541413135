import React from 'react';
import '../../AdminSass/adminSidebar.scss'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../services/ApiUrl';

function AdminSidebar({ handleOpenModal, toggleSidebarClass, handleOpenTitle, handleSetting }) {

    const [isDivVisible, setIsDivVisible] = useState(false);

    //sidebar
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [sidebarW767, setSidebarW767] = useState(true);

    //api

    const [admin_email, setAdminEmail] = useState()


    const handleClick = () => {
        setIsDivVisible(!isDivVisible);
    };

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleClose = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleW767 = () => {
        setSidebarW767(!sidebarW767);
        toggleSidebarClass(sidebarW767 ? 'sidebar-w-767' : '');
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (windowWidth > 767) {
            setSidebarW767(false);
        }
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);

    const shouldRemoveSidebar = windowWidth <= 767;

    const navigate = useNavigate();
    useEffect(() => {
        api.get('/users/me')
            .then((res) => {
                console.log(res.data[0].user.id, 'user');
                setAdminEmail(res.data[0].user.email);
            }).catch((err) => {
                console.log(err, 'user error')
            })
    }, [])

    const handelLogOut = () => {
        axios.post('https://zetapi.techenablers.info/api/logout', null, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'Accept': 'application/json',
                'content-type': 'application/json'
            }
        }).then((res) => {
            console.log(res, 'logout res');
            navigate('/');
            toast.success(res.data.data.message)

            if (res.status === 200) {
                localStorage.removeItem('token')
                localStorage.removeItem('admin')
            }

        }).catch((error) => {
            console.log(error, 'logout error')
        })
    }

    const GoChangePassword = () => {
        navigate('/changepassword')
    }

    return (
        <React.Fragment>
            <div>
                <div className='sidebar-btn-w-767' onClick={handleW767}>
                    <div className='sidebar-btn-w-767-div'>
                        <div className="icon"></div>
                    </div>
                </div>
                <div className={`tool open-sidebar ${isSidebarVisible ? 'hidden' : ''}`} onClick={handleClose} data-toggle="tooltip" data-placement="top" title="Open sidebar">
                    <div className='side-open-div'>
                        <div className="icon"></div>
                    </div>
                </div>
                <div className={`${isSidebarVisible && !shouldRemoveSidebar ? 'sidebar' : 'hidden'} ${sidebarW767 ? 'sidebar-w-767' : 'sidebar-w-0'}`}>
                    <div className='sidebar-content-admin'>
                        <div className='sidebar-top'>
                            <div className='part-one'>
                                <button className='chat' onClick={handleOpenTitle}>
                                    <i className='plus-icon'></i>
                                    <div className='new-chat'><p>New chat</p></div>
                                </button>
                                <div className='side-icon' onClick={toggleSidebar}>
                                    <div className='sidebar-icon'></div>
                                </div>
                            </div>
                            <div className='part-two'>
                                <i className='search-icon'></i>
                                <input placeholder='Search...' />
                            </div>
                        </div>
                        <div className='sidebar-menu'>
                            <div className='chat-section'>
                                <div className='heading'>
                                    <span>Today</span>
                                </div>
                                <ul>

                                    <li className='chat active'>
                                        <div className="chat-content">
                                            <div className='icon'></div>
                                            <div className='text'>
                                                <p>Admin</p>
                                            </div>
                                            <div className='actions'>
                                                {/* <div className="edit"></div> */}
                                                {/* <div className="share"></div> */}
                                                {/* <div className='delete'></div> */}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                    <div className='sidebar-footer'>
                        {isDivVisible ? (
                            <div className='profile-options'>
                                <ul className="list-group option-group">
                                    <li className="list-group-item option-item" onClick={GoChangePassword}>
                                        <div className='option'>
                                            <div className='delete'></div>
                                            <div className='text'>Change Password</div>
                                        </div>
                                    </li>
                                    <li className="list-group-item option-item" onClick={() => { handleSetting(); handleClick() }}>
                                        <div className='option'>
                                            <div className='setting'></div>
                                            <div className='text'>Settings</div>
                                        </div>
                                    </li>
                                    <li className="list-group-item option-item">
                                        <div className='option'>
                                            <div className='help'></div>
                                            <div className='text'>Change Image</div>
                                        </div>
                                    </li>
                                    <li className="list-group-item option-item logout-border" onClick={handelLogOut}>
                                        <div className='option'>
                                            <div className='logout'></div>
                                            <div className='text'>Log out</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        ) : (
                            ''
                        )
                        }
                        <div className="profile-section" onClick={handleClick}>
                            <div className='profile-div'>
                                <div className='profile-user'>
                                    <div className='profile-icon'></div>
                                    <div className='text'>
                                        <p>{admin_email}</p>
                                    </div>
                                </div>
                                <div className='option-icon'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >

    )
}

export default AdminSidebar