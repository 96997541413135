import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Pages/services/ApiUrl";

export const fetchChats = createAsyncThunk('fetchChats', async () => {
    const response = await api.get('/chats');
    // console.log(response.data[0], 'fetch chats response')
    return response.data[0];
})

export const postChat = createAsyncThunk('postChat', async (payload) => {
    try {
        const response = await api.post('/chats', payload);
        // console.log(response, 'post chat response')
        return response.data[0];
    } catch (error) {
        console.log(error, 'post chat response')
    }
})

export const deleteChat = createAsyncThunk('deleteChat', async ({ id }) => {
    try {
        const response = await api.delete(`/chats/{id}?chat_id=${id}`);
        // console.log(response, 'delete chat response')
        return response;
    } catch (error) {
        console.log(error, 'chat delete error')
    }
})

export const searchChat = createAsyncThunk('searchChat', async ({ inputSearch }) => {
    const response = await api.get(`/chats?title=${inputSearch}`);
    console.log(response.data[0], 'search chat response')
    return response.data[0];
})

const chatsSlice = createSlice({
    name: 'userChats',
    initialState: {
        chats: [],
        error: null,
        isLoading: false
    },
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(fetchChats.fulfilled, (state, action) => {
            // console.log(action.payload.chats, 'action fetch chats')
            state.error = null;
            state.chats = action.payload.chats;
        });

        // builder.addCase(postChat.fulfilled, (state, action) => {
        //     console.log(action.payload, 'action post chat')

        //     state.error = null;
        //     // state.chats = action.payload.chat;
        //     state.chats = [...state.chats, action.payload.chat];

        //     // state?.chats?.push(action.payload.chat);
        // });

        // builder.addCase(postChat.rejected, (state, action) => {
        //     console.log(action, 'action post chat error')

        //     // state.error = action.payload;
        //     // state.chats = action.payload.chat;
        //     state.chats = [];

        //     // state?.chats?.push(action.payload.chat);
        // });

        builder.addCase(postChat.fulfilled, (state, action) => {
            console.log(action.payload, 'action post chat');

            if (action.payload.success === true) {
                state.error = null;
                state.chats = [...state.chats, action.payload.chat];
            } else {
                state.error = action.payload.messages[0];
            }
        });

        builder.addCase(postChat.rejected, (state, action) => {
            console.log(action, 'action post chat error');

            state.error = action.error.message;
            state.chats = [];
        });


        builder.addCase(deleteChat.fulfilled, (state, action) => {
            // console.log(action, 'action delete chat')

            state.error = null;
            if (action.payload.success) {
                if (action.payload.messages && action.payload.messages[0] === 'User not found') {
                    state.chats = state.chats.filter((chat) => chat.id !== action.meta.arg.id);
                } else {
                    state.chats = state.chats.filter((chat) => chat.id !== action.meta.arg.id);
                }
            } else {
                console.log(action, 'action in elses')
            }
        });

        builder.addCase(searchChat.fulfilled, (state, action) => {
            console.log(action, 'search chat action');

            state.error = null;
            state.chats = action.payload.chats;

        })
    }
})

export default chatsSlice.reducer