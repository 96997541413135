import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Sidebar from './componet/Sidebar'
import '../sass/contentCopy.scss'
import '../sass/content.scss'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useChat } from './handleId'
import ClipLoader from 'react-spinners/ClipLoader';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Theme from './componet/Theme'
import { useNavigate } from 'react-router-dom'

//slice files

import { deleteConversation, fetchConversations, postConversation, postTemplate } from '../redux/slices/userSlice'
import { fetchChats, postChat } from '../redux/slices/chatsSlice'
import { setPersona, setProsonaValue, updatePersonaValue } from '../redux/slices/personaSlice';


function Conversations({ isSidebarVisible, contentClass1 }) {
    const dispatch = useDispatch();
    const textareaRef = useRef(null);
    const customDarkStyle = {
        ...dark,
        'pre[class*="language-"]': {
            ...dark['code[class*="language-"]'],
            background: "black",
            padding: '10px'
        },
    }

    const location = useLocation();
    const navigate = useNavigate();
    const [contentClass, setContentClass] = useState('');

    const [isLoading, setIsLoading] = useState(false)
    const [questionLoading, setQuestionLoading] = useState(false)
    const [templateLoading, setTemplateLoading] = useState(false)
    const { chatId, setChatId, getModel, setGetModel, getTitle, setGetTitle, firstCharacters } = useChat();
    const persona = useSelector((state) => state.persona.persona);
    const prosonaValue = useSelector((state) => state.persona.prosonaValue);
    const selected = useSelector((state) => state);
    const [title, setTitle] = useState('');
    const [model, setModel] = useState('');
    const [question, setQuestion] = useState('');
    const [templateQuestion, setTemplateQuestion] = useState('')
    const [showTemplate, setShowTemplate] = useState(false)
    const [error, setError] = useState([])
    const [showError, setShowError] = useState(false)
    const [showButtonDisable, setShowButtonDisable] = useState(false)
    const chatContainerRef = useRef(null);

    // slice states

    const reduxConversations = useSelector((state) => state.user.conversations.conversations || []);
    const [chatConversations, setChatConversations] = useState([])
    const chats = useSelector((state) => state.userChats?.chats || []);

    const toggleSidebarClass = (newClass) => {
        console.log(contentClass, 'contentClass');
        setContentClass(newClass);
    };
    const [show, setShow] = useState(false);
    const [showTitle, setShowTitle] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);

    // useEffect(() => {
    //     const storedPersonaValue = localStorage.getItem('personaValue');

    //     if (storedPersonaValue) {
    //         dispatch(setProsonaValue(storedPersonaValue));
    //     }
    // }, []);

    // const setPersonaValueInLocalStorage = (newPersonaValue) => {
    //     console.log(newPersonaValue, 'newPersonaValue')
    //     dispatch(setProsonaValue(newPersonaValue));
    //     localStorage.setItem('personaValue', newPersonaValue);
    // };
    const [themeValue, setThemeValue] = useState();
    const theme = localStorage.getItem('theme')
    if (!theme) {
        localStorage.setItem('theme', 'Light')
    }

    const handleClose = () => {
        setShow(false);
        // setPersona('');
        setTemplateQuestion('');
        setSelectedTemplate([]);
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleCloseTitle = () => {
        setShowTitle(false)
    }
    const handelShowTitle = () => {
        setShowTitle(true)
    }

    const handleSetting = () => {
        setOpenSetting(!openSetting)
    }

    const scrollToBottom = (e) => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom()
    }, [chatId, reduxConversations, chatConversations])

    const handlePostTitle = () => {
        setShowButtonDisable(true)
        let payload = {
            title: title,
            model: model
        }

        dispatch(postChat(payload))
            .then((res) => {
                console.log(res, 'post chat response');
                setShowButtonDisable(false)
                if (res.payload.success === true) {
                    toast.success('Title Created');
                    setGetModel(res.payload.chat.model);
                    setChatId(res.payload.chat.id);
                    navigate('/c/' + res.payload.chat.id)
                    setGetTitle(res.payload.chat.title);
                    setShowButtonDisable(false);
                    setTitle('');
                    setModel('');
                    // localStorage.removeItem('personaValue')
                }

                if (res.payload.success === false) {
                    toast.error(res.payload.messages[0]);
                    setShowButtonDisable(false);
                    setTitle('');
                    setModel('');
                }
            }).catch((error) => {
                console.log(error, 'post chat error');
            }).finally(() => {
                setShowButtonDisable(false);
                setShowTitle(false);
            })
    }


    const chatResponse = () => {
        setIsLoading(true)
        dispatch(fetchConversations(chatId))
            .then((res) => {
                // console.log(res, 'res')
                setChatConversations(res.payload.conversations);
                if (res.payload.success === true) {
                    setShowError(false);
                } else if (res.payload.success === false) {
                    setShowError(true);
                    setError(res.payload.messages);
                    console.log(res.payload.messages, 'error')
                } else if (res.payload.code === "ERR_NETWORK") {
                    setShowError(true);
                    setError("Network Error")
                } else if (res.payload.status !== 200) {
                    setShowError(true);
                    setError(res.payload.data.detail)
                }

            }).catch((error) => {
                console.log(error, 'error')
            }).finally(() => {
                setIsLoading(false);
            });

    };


    useEffect(() => {
        const Chatslength = chats.length;
        if (Chatslength >= 1) {
            setShowTemplate(true)
        } else {
            setShowTemplate(false)
        }
        if (chatId) {
            chatResponse(false);
        }
    }, [chatId])

    useEffect(() => {
        const idFromUrl = window.location.href;
        const parts = idFromUrl.split('/');
        console.log(parts);
        setChatId(parts[4]);
        setChatConversations(reduxConversations)
    }, [])

    useEffect(() => {
        if (!chatId) {
            setShowError(true);
            setError('create chat first. if have click on it');
            setModel('');
            setGetTitle('');
        }
    }, [])

    const handlePostQuestion = (e) => {
        const payload = {
            message: question,
            chat_id: chatId,
            context: persona
        };

        if (question.trim() !== '') {
            setQuestionLoading(true);
            setQuestion('')

            console.log(chatId)
            dispatch(postConversation({ chatId, payload }))
                .then((res) => {
                    console.log(res, 'post question console')
                    if (res.payload.success === true) {
                        setQuestion('');
                        chatResponse();
                    } else if (res.payload.code === "ERR_NETWORK") {
                        console.log('hello')
                        setShowError(true)
                        setError(res.payload.message)
                        setQuestion('')
                    } else {
                        setShowError(true)
                        setError(res.payload.response.data.detail)
                    }
                    if (chatContainerRef.current) {
                        setTimeout(() => {
                            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                        }, 100);
                    }

                }).catch((error) => {
                    setShowError(true);
                }).finally(() => {
                    setQuestionLoading(false);
                    setIsLoading(false)
                })
        }
    };

    const copyText = (text) => {
        text = text.trim();
        navigator.clipboard.writeText(text)
        toast.success('Text copied to clipboard');
    };

    const copyCode = (copyCode) => {
        copyCode = copyCode.trim();
        navigator.clipboard.writeText(copyCode)
        toast.success('Code copied to clipboard');
    };

    const templates = [
        {
            "tempalte": "PHP Developer",
            "persona": "Act as: PHP Developer",
            "question": "Write a recursive function."
        },
        {
            "tempalte": "Python Developer",
            "persona": "Act as: Python Developer",
            "question": "Write a recursive function."
        },
        {
            "tempalte": "React Developer",
            "persona": "Act as: React Developer",
            "question": "Write a recursive function."
        },
        {
            "tempalte": "Android Developer",
            "persona": "Act as: Android Developer",
            "question": "Write a recursive function."
        }
    ];

    const [selectedTemplate, setSelectedTemplate] = useState([]);

    const handleChange = (e) => {
        const selected = templates.find(template => template.tempalte === e.target.value);
        const persona = selected.persona;
        const templateQuestion = selected.question;
        const selectedTemplate = selected;
        console.log(persona);
        dispatch(setPersona(persona));
        setTemplateQuestion(templateQuestion);
        setSelectedTemplate(selectedTemplate);
    };

    const handleTemplate = () => {
        const payload = {
            message: templateQuestion,
            chat_id: chatId,
            context: persona
        };
        console.log('payload:', payload);
        if (templateQuestion.trim() !== '') {
            setTemplateLoading(true)
            setTemplateQuestion('')
            dispatch(postTemplate({ chatId, payload }))
                .then((res) => {
                    console.log(res, 'post template response')
                    if (res.payload.success === true) {
                        // setPersonaValueInLocalStorage(persona);
                        dispatch(setProsonaValue(persona));

                        setTemplateQuestion('');
                        setSelectedTemplate([]);
                        chatResponse(false);

                    } else if (res.payload.code === "ERR_NETWORK") {
                        setShowError(true)
                        setError(res.payload.message)
                        setTemplateQuestion('');
                        setSelectedTemplate([])
                    }
                    if (res.payload.status !== 200) {
                        setShowError(true)
                        setError(res.payload.data.detail)
                        setTemplateQuestion('');
                        setSelectedTemplate([])
                    }
                }).catch((error) => {
                }).finally(() => {
                    if (chatContainerRef.current) {
                        setTimeout(() => {
                            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                        }, 100);
                    }
                    setTemplateLoading(false)
                    handleClose();
                    setIsLoading(false)
                })
        } else {
            setTemplateLoading(false)
            handleClose()
        }
    }

    const handleDelete = async (id) => {
        console.log(id);

        const updatedConversations = chatConversations.filter(
            (conversation) => conversation.id !== id
        );

        console.log(updatedConversations, 'updatedConversations');
        setChatConversations(updatedConversations)

        dispatch({ type: 'UPDATE_CONVERSATIONS', payload: updatedConversations });

        try {
            const res = await dispatch(deleteConversation({ id }));

            console.log(res, 'deleteConversation api then res');

            if (res.payload === undefined) {
                if (res.error.code === "ERR_NETWORK") {
                    toast.error("Network Error");
                }
            } else {
                toast.success(res.payload.data[0].messages[0]);
            }
            chatResponse();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleInput = () => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto'; // Reset height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set new height
    };

    const handleThemeChange = (e) => {
        const newValue = e.target.value;
        setThemeValue(newValue);
        localStorage.setItem('theme', newValue);
    };

    return (
        <div className='wrapper'>
            <Sidebar handleOpenModal={handleShow} handleOpenTitle={handelShowTitle} isSidebarVisible={isSidebarVisible} toggleSidebarClass={toggleSidebarClass} chatId={chatId} handleSetting={handleSetting} />
            <div className={`${theme === 'Light' ? 'main-wrapper' : 'dark-wrapper'} ${contentClass1}`}>
                {/* <Navbar /> */}
                <div className='navbar-1'>
                    {/* ${contentClass === 'sidebar-w-767' ? 'navbar-1' : 'navbar-two'} */}
                    <div className='mlr'>
                        <div className='navbar-componet navbar-2'>
                            <ul className="nav-select">
                                <li className='model-li'>
                                    <p className={`${theme === 'Dark' ? 'p-white' : ' '}`} style={{ padding: '0px', margin: '0px' }}>{getModel}</p>
                                    <small className={`${theme === 'Dark' ? 'p-white' : ' '}`}>{getTitle.length > 32 ? `${getTitle.slice(0, 32)}...` : `${getTitle}`}</small>
                                </li>
                            </ul>
                        </div>
                        <div className='persona-text'>
                            <p className={`${theme === 'Dark' ? 'p-white' : ' '}`}>{prosonaValue}</p>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div className='dashboard'>
                    <div className='content'>
                        <main className='h-full w-full flex flex-col flex-1'>
                            <div className="wrapper-container">
                                <div className='dashboard-main-content' ref={chatContainerRef}>
                                    {showError ? (
                                        <div className={`text-center ${theme === 'Dark' ? 'p-white' : ''}`}>{error}</div>
                                    ) :

                                        (
                                            <>
                                                {isLoading ?
                                                    (
                                                        <div className='text-center loader-center' style={{ margin: 'auto' }}>
                                                            <h3 className={`${theme === 'Dark' ? 'p-white' : ''}`}>Wait...</h3>
                                                        </div>
                                                    ) :

                                                    (
                                                        <>
                                                            {chatConversations && chatConversations.length > 0 ? (
                                                                <React.Fragment>
                                                                    {chatConversations.map((chatConversation, i) => (
                                                                        <div className={`user-chat ${chatConversation.type === 'Q' ? (theme === 'Light' ? 'bg-white' : 'bg-dark') : (theme === 'Dark' ? 'bg-gray-dark' : 'bg-gray')}`} key={i}>
                                                                            <div className='container max-w-767'>
                                                                                <div className={`${chatConversation.type === 'Q' ? 'mr-auto' : ''}`}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-1 first-col_1"></div>
                                                                                        <div className="col-md-1 first-col_2">
                                                                                            <div className={`${chatConversation.type === 'Q' ? 'user-image_div' : 'chatgpt-div'}`}>
                                                                                                <div className={`${chatConversation.type === 'Q' ? 'user-image' : 'chatgpt-div_icon'}`}>
                                                                                                    <p>{chatConversation.type === 'Q' ? firstCharacters : ''}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-8 pl-0 col-8">
                                                                                            <div className="chat-div_text">
                                                                                                {chatConversation.type === 'A' ? (
                                                                                                    <div>
                                                                                                        {chatConversation.message.split('```').map((part, index) => {
                                                                                                            const lines = part.trim().split('\n');
                                                                                                            const firstLine = lines[0].trim();
                                                                                                            const codeLines = lines.slice(1).join('\n');
                                                                                                            if (index % 2 === 0) {
                                                                                                                return (
                                                                                                                    <p key={index}>
                                                                                                                        {part.split('`').map((textPart, idx) => {
                                                                                                                            if (idx % 2 === 0) {
                                                                                                                                return <p className={`${theme === 'Dark' ? 'p-white' : ''}`}>
                                                                                                                                    {textPart.split('\n\n').map((str, index, array) =>
                                                                                                                                        index === array.length - 1 ? str : <>
                                                                                                                                            {str}
                                                                                                                                            <br />
                                                                                                                                        </>
                                                                                                                                    )}
                                                                                                                                </p>;
                                                                                                                            } else {
                                                                                                                                return <code key={idx}>{textPart}</code>;
                                                                                                                            }
                                                                                                                        })}
                                                                                                                    </p>
                                                                                                                );
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <div key={index}>
                                                                                                                        <div className='code-header'>
                                                                                                                            <p>{firstLine}</p>
                                                                                                                            <button className='copy-button' onClick={() => copyCode(codeLines)}>
                                                                                                                                <div className='copy'></div>
                                                                                                                                <p>copy</p>
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                        <SyntaxHighlighter language="javascript" style={customDarkStyle}>
                                                                                                                            {codeLines}
                                                                                                                        </SyntaxHighlighter>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <p className={`${theme === 'Dark' ? 'p-white' : ''}`}>{chatConversation.message}</p>
                                                                                                )}



                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={`col-md-1 ${chatConversation.type === 'Q' ? '' : 'col-1-icons'}`}>
                                                                                            <div className='icons'>
                                                                                                <span className={`${chatConversation.type === 'Q' ? "copy-q" : 'copy'}`} onClick={() => copyText(chatConversation.message)}></span>
                                                                                                <span className={`${chatConversation.type === 'Q' ? "delete-q" : 'delete-a'}`} onClick={() => handleDelete(chatConversation.id)}></span>
                                                                                                <span className={`${chatConversation.type === 'Q' ? "" : 'like'}`}></span>
                                                                                                <span className={`${chatConversation.type === 'Q' ? "" : 'dislike'}`}></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-1"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </React.Fragment>
                                                            ) : ''}

                                                        </>
                                                    )
                                                }

                                            </>
                                        )}

                                </div>
                                <div className='bottom-content'>
                                    <div className='bottom-div'>
                                        <form onSubmit={handlePostQuestion}>
                                            <textarea type="text" placeholder='Send a message'
                                                className={`input-send ${theme === 'Dark' ? 'dark-textarea' : ''}`}
                                                height="auto"
                                                ref={textareaRef}
                                                onInput={handleInput}
                                                name='search' value={question} onChange={(e) => setQuestion(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13 && !e.shiftKey) {
                                                        e.preventDefault();
                                                        handlePostQuestion();
                                                    }
                                                }}
                                            ></textarea>

                                            {questionLoading ? (
                                                <div className={`${theme === 'Light' ? 'loadingQuestion' : 'loadingQuestion-dark'}`} >
                                                    <ClipLoader loading={questionLoading} size={20} color="" />
                                                </div>
                                            )
                                                :
                                                (
                                                    <>
                                                        <i className='search-icon' onClick={handlePostQuestion}></i>
                                                    </>
                                                )
                                            }

                                            {/* <button className='btn-bottom' onClick={scrollToBottom}>
                                                <i className="fa fa-home"></i>
                                            </button> */}
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </main>
                    </div>
                </div>
            </div>

            <Modal className='editorder_window' show={show} onHide={handleClose}>
                <Modal.Header className='header-close'>
                    <button className='close-icon' onClick={handleClose}></button>
                </Modal.Header>
                <Modal.Body>
                    {showTemplate ? (
                        <div className="modal-div">
                            {/* Template */}
                            <div className="row">
                                <div className="col-md-12 tem-style">
                                    <label>Model</label>
                                    <select class="custom-select selector-modal">
                                        <option selected>Select Modle</option>
                                        <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
                                        <option value="gpt-3.5-turbo-16k">gpt-3.5-turbo-16k</option>
                                        <option value="gpt-4">gpt-4</option>
                                        <option value="4o">4o</option>
                                        <option value="4o-mini">4o-mini</option>
                                    </select>
                                </div>
                                <div className="col-md-12 tem-style">
                                    <label>Templates</label>
                                    <select class="custom-select selector-modal" onChange={handleChange}>
                                        <option selected>Templates</option>
                                        {templates.map(template => (
                                            <option key={template.tempalte} value={template.tempalte}>
                                                {template.tempalte}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-12">
                                    {selectedTemplate && (
                                        <>
                                            <form onSubmit={handleTemplate}>
                                                <div className="row">

                                                    <div className='col-md-6 textarea-div'>
                                                        <label>Persona</label>
                                                        <textarea placeholder='Write...' defaultValue={selectedTemplate.persona} onChange={(e) => dispatch(setPersona(e.target.value))}></textarea>
                                                    </div>

                                                    <div className='col-md-6 textarea-div'>
                                                        <label>Question</label>
                                                        <textarea placeholder='Write...' defaultValue={selectedTemplate.question} onChange={(e) => setTemplateQuestion(e.target.value)}></textarea>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : ''}

                </Modal.Body>
                <Modal.Footer className='modal-footer-div'>
                    {showTemplate ? (
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8 modal-btn-div">
                                <button className='btn btn-block btn-modal' type='button' disabled={showButtonDisable} onClick={handleTemplate}>
                                    {templateLoading ? (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <ClipLoader loading={templateLoading} size={20} color="black" />
                                        </div>
                                    ) : (

                                        'Submit'
                                    )}
                                </button>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                            <p style={{ textAlign: 'center' }}>Create Chat First!</p>
                        </div>
                    )}

                </Modal.Footer>
            </Modal>

            <Modal className='editorder_window' show={showTitle} onHide={handleCloseTitle}>
                <Modal.Header className='header-close'>
                    <button className='close-icon' onClick={handleCloseTitle}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mt-2">
                            <div className="row">
                                <div className='col-md-3'></div>
                                <div className="col-md-6 d-center">
                                    <input type='text' placeholder='Chat Title' className='title-input' name='title' onChange={((e) => setTitle(e.target.value))} />
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-2">
                            <div className="row">
                                <div className='col-md-3'></div>
                                <div className="col-md-6 d-center">
                                    <div className='navbar-componet'>
                                        <select class="custom-select title-input-select" name='model' onChange={(e) => setModel(e.target.value)}>
                                            <option selected>Select Modle</option>
                                            <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
                                            <option value="gpt-3.5-turbo-16k">gpt-3.5-turbo-16k</option>
                                            <option value="gpt-4">gpt-4</option>
                                            <option value="4o">4o</option>
                                            <option value="4o-mini">4o-mini</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-footer-div'>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 modal-btn-div">
                            <button className='btn btn-block btn-modal' disabled={showButtonDisable} onClick={handlePostTitle}>
                                Submit
                            </button>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* theme */}

            <Modal className='editorder_window' show={openSetting} onHide={handleSetting}>
                <Modal.Header className='header-close'>
                    <button className='close-icon' onClick={handleSetting}></button>
                </Modal.Header>
                <Modal.Body>

                    <div className="modal-div">
                        <div className="row">
                            <div className="col-md-12 tem-style">
                                <label>Theme</label>
                                <select class="custom-select selector-modal" onChange={handleThemeChange}>
                                    <option selected disabled>Select Theme</option>
                                    <option value='Light'>Light</option>
                                    <option value="Dark">Dark</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Conversations