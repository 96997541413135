import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../services/ApiUrl';
import ClipLoader from 'react-spinners/ClipLoader';
import Sidebar from '../componet/Sidebar';
import { toast } from 'react-toastify';
import { useChat } from '../handleId';
import Modal from 'react-bootstrap/Modal';
import AdminSidebar from '../admin/AdminSidebar';

function ChangePassword({ isSidebarVisible, contentClass1 }) {
    const { chatId } = useChat();
    const [error, setError] = useState([]);
    const [closeError, setCloseError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState()
    const [new_Password, setNewPassword] = useState();
    const [old_Password, setOldPassword] = useState();
    const [confirm_Password, setConfirmPassword] = useState()
    const [showError, setShowError] = useState();
    const [user, setUser] = useState(false)
    const [contentClass, setContentClass] = useState('');
    const [themeValue, setThemeValue] = useState();
    const theme = localStorage.getItem('theme');

    const navigate = useNavigate();

    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const [openSetting, setOpenSetting] = useState(false);




    const toggleSidebarClass = (newClass) => {
        setContentClass(newClass);
    };

    const clearErrors = () => {
        setError([]);
    };
    useEffect(() => {
        api.get('/users/me')
            .then((res) => {
                console.log(res, 'user')
                setUserId(res.data[0].user.id)
                if (res.data[0].user.roles === 'user') {
                    setUser(true)
                }
            }).catch((err) => {
                console.log(err, 'user error')
            })
    }, [])

    const handlePassword = (e) => {
        e.preventDefault();
        let payload = {
            new_password: new_Password,
            old_password: old_Password,
            confirm_password: confirm_Password
        }
        if (new_Password !== confirm_Password) {
            console.log("New Password and Confirm Password do not match");
            setIsLoading(false);
            setPasswordsMatch(false);
            return;
        }
        setIsLoading(true)
        api.post(`/update-password/${userId}`, payload)
            .then((res) => {
                console.log(res.data[0].messages[0])
                console.log(res)
                if (res.data[0])
                    if (res.data[0].success === true) {
                        if (user === true) {
                            if (chatId) {
                                navigate('/c/' + chatId)
                            } else {
                                navigate('/content')
                            }
                        } else {
                            navigate('/admin')
                        }
                        toast.success(res.data[0].messages[0])
                    } else {
                        setShowError(true)
                        setError(res.data[0].messages)
                    }

            }).catch((err) => {
                console.log(err, 'error')
                if (err.code === "ERR_NETWORK") {
                    setError(['NetWork Error']);
                }
                else {
                    // setError([err.response.data.detail])
                }
                setTimeout(() => {
                    setError([]);
                }, 5000)
            }).finally(() => {
                setIsLoading(false)
                setShowError(false)
            })
    };

    const customLoaderStyles = {
        width: '20px',
        height: '20px',
        marginTop: '9px',
        borderRadius: '100%',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'rgb(5, 5, 5) rgb(5, 5, 5) transparent',
        borderImage: 'initial',
        display: 'inline-block',
        animation: '0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip',
    };


    const handleSetting = () => {
        setOpenSetting(!openSetting)
    }

    const handleThemeChange = (e) => {
        const newValue = e.target.value;
        setThemeValue(newValue);
        localStorage.setItem('theme', newValue);
    };

    return (
        <div className='wrapper'>
            {user ? (
                <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebarClass={toggleSidebarClass} handleSetting={handleSetting} />
            ) :

                (
                    <AdminSidebar />
                )}
            <div className={`${theme === 'Light' ? 'main-wrapper' : 'dark-wrapper'} ${contentClass1}`}>
                {/* <Navbar /> */}
                <div className='dashboard'>
                    <div className='content'>
                        <main className='h-full w-full flex flex-col flex-1'>
                            <div className="wrapper-container">
                                <div className='dashboard-main-content_admin'>

                                    <div className='login-page overflow-y-auto'>
                                        <div className='login-box'>
                                            <div className='login-logo'>
                                                <h4 className={`${theme === 'Dark' ? 'heading_white' : 'heading'}`}>Change Password</h4>
                                                <p className={`${theme === 'Dark' ? 'para_white' : 'para'}`}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque, perferendis.</p>
                                            </div>
                                            <div className='card'>
                                                {error.length > 0 &&
                                                    error.map((e, i) => {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                <div className={` ${closeError ? 'hiddenError' : 'error'}`}>
                                                                    <div className='error-div'>
                                                                        <div className='msg'>
                                                                            <p>{e}</p>
                                                                        </div>
                                                                        <div className='close-icon' onClick={clearErrors}></div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                <form onSubmit={handlePassword}>
                                                    <div className={`${theme === 'Dark' ? 'card-body_dark' : 'card-body'}`}>
                                                        <input type='password' placeholder='Old Password' className='email-input' value={old_Password} onChange={(e) => setOldPassword(e.target.value)} />
                                                        <input type='password' placeholder='New Password' className='email-input' value={new_Password} onChange={(e) => setNewPassword(e.target.value)} />
                                                        <input
                                                            type="password"
                                                            placeholder="Confirm Password"
                                                            className={`email-input ${new_Password && confirm_Password && new_Password !== confirm_Password ? 'password-mismatch' : confirm_Password ? 'password-match' : ''}`}
                                                            value={confirm_Password}
                                                            onChange={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                                setPasswordsMatch(e.target.value === new_Password);
                                                            }}
                                                        />

                                                        <button className='btn btn-continue' onClick={handlePassword}>
                                                            {isLoading ? (
                                                                <ClipLoader style={customLoaderStyles} loading={isLoading} size={20} color="#050505" />
                                                            ) :
                                                                'Continue'
                                                            }
                                                        </button>
                                                        {/* <div className='sign-para'>
                                                            <p>
                                                                Don't want to change? <span><Link style={{ color: '#10A37F' }} to='/content'>Click here!</Link></span>
                                                            </p>
                                                        </div> */}
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </main>
                    </div>
                </div>
            </div>

            <Modal className='editorder_window' show={openSetting} onHide={handleSetting}>
                <Modal.Header className='header-close'>
                    <button className='close-icon' onClick={handleSetting}></button>
                </Modal.Header>
                <Modal.Body>

                    <div className="modal-div">
                        <div className="row">
                            <div className="col-md-12 tem-style">
                                <label>Theme</label>
                                <select class="custom-select selector-modal" onChange={handleThemeChange}>
                                    <option selected disabled>Select Theme</option>
                                    <option value='Light'>Light</option>
                                    <option value="Dark">Dark</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>

    );
}

export default ChangePassword;
