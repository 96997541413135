import React, { createContext, useContext, useState } from 'react';

const PersonaContext = createContext();

export function PersonaProvider({ children }) {
    const [persona, setPersona] = useState('');
    const [prosonaValue, setProsonaValue] = useState()

    const contextValue = {
        persona,
        setPersona,
    };

    return (
        <PersonaContext.Provider value={contextValue}>
            {children}
        </PersonaContext.Provider>
    );
}

export function usePersona() {
    return useContext(PersonaContext);
}
