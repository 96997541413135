// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// const Auth = (props) => {
//     const { Component } = props;
//     const navigate = useNavigate();
//     const [isLoggedIn, setIsLoggedIn] = useState(false);

//     useEffect(() => {
//         const checkUserToken = () => {
//             const userToken = localStorage.getItem('token');
//             if (!userToken || userToken === 'undefined') {
//                 setIsLoggedIn(false);
//                 navigate('/');
//                 // console.log('hello checkUserToken')
//             } else {
//                 setIsLoggedIn(true);
//             }
//         }

//         // const checkExpireToken = () => {
//         //     const userExpireToken = localStorage.getItem('token');
//         //     if (!userExpireToken || userExpireToken === 'undefined') {
//         //         setIsLoggedIn(false);
//         //         navigate('/');
//         //         // console.log('hello checkExpireToken')

//         //     }
//         // }

//         checkUserToken();
//         // checkExpireToken();
//     }, []);

//     return (
//         <>
//             {isLoggedIn ? <Component /> : null}
//         </>
//     );
// }

// export default Auth;


import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";

const Auth = (props) => {
    const { Component } = props;
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            navigate('/');
        } else {
            setIsLoggedIn(true);
        }
    }

    useEffect(() => {
        checkUserToken();
    }, []);

    return (
        <>
            {isLoggedIn && <Component />}
        </>
    );
}

export default Auth;
